<template>
  <ErrorBoundary>
    <Page v-bind="pageData">
      <div class="u-display--flex u-justify-content--center u-padding-top--1">
        <Spinner aria-label="Loading" :show="true" />
        Loading...
      </div>
    </Page>
  </ErrorBoundary>
</template>

<script lang="ts">
import Vue from "vue";
import Spinner from "atlas/src/components/Spinner/Spinner.vue";
import { InitPageParams } from "lib/utils/initPage";
import Page from "~/components/Page/Page.vue";
import { initPage } from "~/lib/utils/initPage";
import { getJsonLd } from "~/lib/mappers/JsonLdMapper";
import { utag } from "~/components/ModularPage/ContentstackModularPageContentDecorator";
import { loggerFactory, logTags } from "~~/lib/utils/logger/logger";
import ErrorBoundary from "~/components/ErrorBoundary/ErrorBoundary.vue";
import { createLink } from "~/lib/utils/link/createLink";

const logger = loggerFactory({
  tags: [logTags.Layer.Page],
});

export default Vue.extend({
  components: {
    ErrorBoundary,
    Spinner,
    Page,
  },
  async asyncData({
    store,
    i18n,
    route,
    params,
    redirect,
    $config,
  }): Promise<void> {
    try {
      const initPageParams: InitPageParams = {
        locale_iso: i18n.localeProperties.iso,
        locale_code: i18n.locale,
        locale_domain: $config.public.baseUrl,
        path: route.path,
        slug: `/${params.pathMatch}`,
        currency_code:
          params.currencyCode || i18n.localeProperties.currencyCode,
      };
      await Promise.all(initPage(store, initPageParams));

      await redirect(createLink(i18n.locale)("/"));
    } catch (error) {
      logger.error("Auth0 logout failed", error);
    }
  },
  computed: {
    pageData() {
      const metaData = [
        {
          property: "og:url",
          content: `${this.$config.public.baseUrl}${this.$route.fullPath}`,
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:image",
          content: this.$config.public.defaultMetaImage,
        },
      ];

      const jsonldData = getJsonLd({
        ...this.$config.public,
      })({
        "@context": "https://schema.org",
        "@type": "WebSite",
        url: this.$config.public.baseUrl,
        name: "Intrepid Travel",
      });

      return {
        jsonld: jsonldData,
        utag: utag(
          this.$i18n.localeProperties,
          this.$route.fullPath,
          "wishlist-logout-page"
        ),
        metadata: metaData,
        title: "My wishlist | Intrepid Travel",
        banner: undefined,
        breadcrumb: undefined,
      };
    },
  },
});
</script>
